





























































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import SygniTable from "@/components/table/SygniTable.vue";
import { Prop } from 'vue-property-decorator/lib/decorators/Prop';
import { BSpinner } from 'bootstrap-vue';

@Component({
  components: {BSpinner, SygniArrowButton, SygniRoundedButton, UserPresentation, ExpandCollapse }
})
export default class ProductHistoryTable extends SygniTable<any> {
  @Prop({default: ''}) productId!: string;

  tableFields = [
    {key: 'date', sortable: true, class: ['date', 'text-left']},
    {key: 'name', label: 'User', sortable: true, class: ['user', 'text-left']},
    {key: 'parties', label: 'Parties', sortable: true, class: ['user-2', 'text-left']},
    {key: 'productStatus', sortable: true, label: 'Status', class: 'action-2 text-left'},
    {key: 'action', sortable: true, label: 'Action', class: 'action-2 text-left'},
  ]
    
  get filtersQuery() {
    return this.$store.getters['products/getProductsTableFiltersQuery'];
  }

  getActionLabel(label: string) {
    switch(label) {
      case('CREATE'):
        label = 'Product created.'
        break;
      case('UPDATE'):
        label = 'Product updated.';
        break;
      case('UPDATE_RATE'):
        label = 'Exchange rate updated.';
        break;
      case('SEND_TO_INVESTOR_FOR_ACCEPTATION'):
        label = 'Product sent to investor for acceptation.';
        break;
      case('ACCEPT_BY_INVESTOR'):
        label = 'Product accepted by investor.';
        break;
      case('ACCEPT_BY_LEGAL_ENTITY'):
        label = 'Product accepted by legal entity.';
        break;
      case('REJECT'):
        label = 'Product rejected.';
        break;
      case('DECLINE'):
        label = 'Product declined.';
        break;
      case('RESTORE'):
        label = 'Product restored.';
        break;
      case('FINISH'):
        label = 'Product finished.';
        break;
      case('UPDATE_AGREEMENT'):
        label = 'Product agreement updated.';
        break;
      case('UPDATE_AGREEMENT_DATA'):
        label = 'Product agreement data updated.';
        break;
      case('UPDATE_PAYMENT_DATA'):
        label = 'Product payment information updated.';
        break;
      case('UPDATE_RESCHEDULE_PAYMENTS'):
        label = 'Product reschedule payments updated.';
        break;
      case('UPDATE_REPAYMENT_SCHEDULE'):
        label = 'Repayment schedule updated.';
        break;
      case('ASSIGN_CAMPAIGN'):
        label = 'Campaign assigned.';
        break;
      case('ANNEX'):
        label = 'Product annexed.';
        break;
      case('SCHEDULE_REPAYMENT'):
        label = 'Repayment scheduled.';
        break;
      case('UPDATE_REPAYMENT_STATUS'):
        label = 'Repayment status updated.';
        break;
      case('CHANGE_OWNER'):
        label = 'Owner changed.';
        break;
      case('UNPAID_REPAYMENT'):
        label = 'Repayment unpaid.';
        break;
      case('DELETE_CLAT_TAX_PAYMENT'):
        label = 'Clat Tax payment deleted.';
        break;
      case('UPDATE_CLAT_TAX_PAYMENT'):
        label = 'Clat Tax payment updated.';
        break;
      case('GENERATE_CLAT_TAX_STATEMENT'):
        label = 'Clat Tax statement generated.';
        break;
      case('ADD_PAYMENT_CONFIRMATION'):
        label = 'Payment confirmation added.';
        break;
      case('DELETE_PAYMENT_CONFIRMATION'):
        label = 'Payment confirmation deleted.';
        break;
      default:
        label = `${this.$options.filters.snakeCaseToTitleCase(label)}.`;
        break;
    }

    return label;
  }

  async getItems() {
    this.isBusy = true;
    try {
      this.items = await this.$store.dispatch('investors/getProductHistoryFromInvestor', this.productId);
    } catch(e) {
      console.log('changes history error', e);
    }
    this.isBusy = false;
  }

  setQuery() {
    this.$store.commit('products/setProductsTableQuery', this.localTableQuery);
  }

  onFiltersChange(filtersQuery?: string): void {
    this.$store.commit(this.setTableBusyMutation, true);
    this.$store.commit('products/setProductsTableFiltersQuery', filtersQuery);
    this.paginationInstance.$emit('changePage', 1);
    this.$nextTick(() => {
      const sign: string = this.sortDesc ? '-' : '';
      let sortBy: string = '';

      switch(this.sortBy) {
        default:
          sortBy = this.sortBy;
          break;
      }

      this.sortingQuery = {
        name: sortBy,
        order: sign,
      }

      this.$store.commit('products/setProductsTableSortingQuery', this.sortingQuery);
      this.getItems();
    });
  }

  onSortChange(): void {
    this.$store.commit(this.setTableBusyMutation, true);
    this.paginationInstance.$emit('changePage', 1);
    this.$nextTick(() => {
      const sign: string = this.sortDesc ? '-' : '';
      let sortBy: string = '';

      switch(this.sortBy) {
        default:
          sortBy = this.sortBy;
          break;
      }

      this.sortingQuery = {
        name: sortBy,
        order: sign,
      }

      this.$store.commit('products/setProductsTableSortingQuery', this.sortingQuery);
      this.getItems();
    });
  }

  constructor() {  // PROTOTYPE COMPONENT
    super();
  }

  mounted() {
    this.getItems();
    this.onMounted();
  }
}

